/* Prevent horizontal overflow on the body */
html, body {
  overflow-x: hidden;
}

/* Smooth scrolling for touch devices like iOS */
.scrollable-content {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.parallax-container {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden; /* Prevents flickering */
  background-attachment: fixed; /* Ensure background is fixed */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repeat */
  background-size: cover; /* Cover the entire container */
}
.App {
  text-align: center;
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Base styles for the parallax container */
.parallax-container {
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
}

/* Mobile images */
@media (max-width: 767px) {
  .parallax-container.pic1 {
    background-image: url('/src/images/pic1a.jpg'); 
  }
  .parallax-container.pic9 {
    background-image: url('/src/images/pic9a.jpg');
  }
  .parallax-container.pic8 {
    background-image: url('/src/images/pic8a.jpg');
  }
  .parallax-container.pic3 {
    background-image: url('/src/images/pic3a.jpg');
  }
  .parallax-container.pic5 {
    background-image: url('/src/images/pic5a.jpg');
  }
  .parallax-container.pic7 {
    background-image: url('/src/images/pic7a.jpg');
  }

}

/* Desktop images */
@media (min-width: 768px) {
  .parallax-container.pic1 {
    background-image: url('/src/images/pic1.jpg');
  }
  .parallax-container.pic9 {
    background-image: url('/src/images/pic9.jpg');
  }
  .parallax-container.pic8 {
    background-image: url('/src/images/pic8.jpg');
  }
  .parallax-container.pic5 {
    background-image: url('/src/images/pic5.jpg');
  }
  .parallax-container.pic3 {
    background-image: url('/src/images/pic3.jpg');
  }
  .parallax-container.pic7 {
    background-image: url('/src/images/pic7.jpg');
  }
}